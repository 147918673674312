// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

function toggleReportsForStatewideContest() {
  const statewide_contest_checkbox =
    document.getElementById("statewide_contest");

  const report_checkboxes = document.querySelectorAll('[id*="_report"]');
  // disable the reports if the statewide contest is not checked
  if (statewide_contest_checkbox.checked === false) {
    report_checkboxes.forEach(function (checkbox) {
      if (checkbox.checked) {
        checkbox.click();
      }
      checkbox.disabled = true;
    });
  } else {
    report_checkboxes.forEach(function (checkbox) {
      checkbox.disabled = false;
    });
  }
}
export default class extends Controller {
  // static targets = ["form", "sponsorships"];

  connect() {
    const vrp_checkbox = document.getElementById("vrp_report");
    const ecr_checkbox = document.getElementById("ecr_report");
    toggleReportsForStatewideContest();
    if (vrp_checkbox.checked === true) {
      if (ecr_checkbox.checked === true) {
        ecr_checkbox.click();
      }
      ecr_checkbox.disabled = true;
    }
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(function (checkbox) {
      if (checkbox.disabled === false && checkbox.dataset.purchased === "Y")
        checkbox.click();
    });
    this.updateTotal();
    console.log("connected");
  }

  updateTotal() {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    let total = 0;
    const total_fields = document.querySelectorAll("[id$='_net_price']");
    total_fields.forEach(function (ttl) {
      if (ttl.value) total += Number(ttl.value.replaceAll(/[$,]/g, ""));
    });
    document.getElementById("total_of_services").value = USDollar.format(total);
  }

  togglePurchase(e) {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    const contest = e.target.dataset.contest;
    const applied_field = document.getElementById(`${contest}_ccc_applied`);
    const net_price = document.getElementById(`${contest}_net_price`);
    const credit = document.getElementById(`${contest}_credit`);
    const ecr_checkbox = document.getElementById("ecr_report");
    toggleReportsForStatewideContest();
    if (e.target.checked === true) {
      if (e.target.id === "vrp_report") {
        if (ecr_checkbox.checked === true) {
          ecr_checkbox.click();
        }
        ecr_checkbox.disabled = true;
      }
      const applyCredit = document.getElementById(`${contest}_ccm`).innerHTML;
      const creditValue = credit.value.replaceAll(/[$,]/g, "");
      const price = document
        .getElementById(`${contest}_price`)
        .value.replaceAll(/[$,]/g, "");
      if (applyCredit === "Y") {
        applied_field.value = "Y";
      } else {
        applied_field.value = "N";
      }
      const creditToApply = applied_field.value;
      if (creditToApply === "Y") {
        net_price.value = USDollar.format(Number(creditValue) + Number(price));
      } else {
        net_price.value = USDollar.format(Number(price));
      }
    } else {
      applied_field.value = "";
      net_price.value = "";
      if (e.target.id === "vrp_report") {
        ecr_checkbox.disabled = false;
      }
    }
    this.updateTotal();
  }
}
